import './scss/index.scss';

// @ts-ignore
import searchImg from "./assets/icon/search.svg";

interface Props {
    name?: string;
    placeholder?: string;
    type?: string;
    value?: string;
    search?: boolean;
    center?: boolean;
    onChange?: Function;
    options?: object[];
}

const Empty: React.FC<Props> = ({
                                    name,
                                    options = [],
                                    placeholder,
                                    type = 'text',
                                    value = "",
                                    onChange,
                                    search = false,
                                    center = false
                                }) => {


    return (
        <div
            className={"input input-select " + (type == 'mini' && ' input-mini ') + (search && 'input-search') + (center && ' input-center ')}>
            {
                type != 'mini' && (
                    <>
                        {
                            search && (
                                <div className="icon-search-input" style={{backgroundImage: "url(" + searchImg + ")"}}></div>
                            )
                        }

                        {
                            name && (
                                <div className="input-label text text-gray2 text-s14">
                                    {name}
                                </div>
                            )
                        }
                    </>
                )
            }


            <select value={value} onChange={(e) => {
                onChange(e.target.value);
            }} className={"input-in text text-s14 " + (type == 'file' && 'input-in-file')}>
                {
                    placeholder && (
                        <option value={''}>{placeholder}</option>
                    )
                }
                {
                    options.map((option) => {
                        return (<option value={option['value']}>{option['name']}</option>)
                    })
                }
            </select>


        </div>
    );

}

export default Empty;