import './scss/index.scss';
// @ts-ignore
import React, {useEffect, useState} from 'react';
// @ts-ignore
import svg1 from "./assets/img/1.svg";
// @ts-ignore
import svg2 from "./assets/img/2.svg";

import TableCustom from "../../../../components/TableCustom";
import Modal from 'react-modal';

Modal.setAppElement('#root');

// ==================== Ваши функции с запросами ====================
import {
    getOnboardingWorkplaceTasks,
    getOnboardingPlanTasks,
    getOnboardingStudyingTasks,
    // Методы для смены статуса
    setWorkplaceStatus,
    setPlanStatus,
    setStudyingStatus,
    // Методы для создания
    createWorkplaceTask,
    createPlanTask,
    createStudyingTask,
    // Список пользователей для селекта
    userList
} from "../../../../services";

import InputMini from "../../../../components/InputMini";
import SelectMini from "../../../../components/SelectMini";
import {useSelector} from "react-redux";
import StateSlices from '../../../../redux/slices/State';
import {TRUE} from "sass";

// Тип, какой онбординг
type OnboardingType = 'workplace' | 'plan' | 'studying';

// Тип задачи
interface ITask {
    id: number;
    theme: string;         // или name, как в вашем API
    executor_name: string; // имя исполнителя (или user)
    status: string;        // «в работе», «выполнено», ...
    checked?: boolean;     // локальное поле для чекбокса
}

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {
    const state_in = useSelector(StateSlices);

    // Какой тип онбординга выбран
    const [activeType, setActiveType] = useState<OnboardingType>('workplace');

    // Массив задач
    const [tasks, setTasks] = useState<ITask[]>([]);

    // Список пользователей (для SelectMini)
    const [userListBox, setUserListBox] = useState([]);

    // Модалка «Создать новую запись»
    const [modalOpen, setModalOpen] = useState(false);

    // Поля новой записи
    const [newTask, setNewTask] = useState({
        executor_id: '',
        theme: '',
        deadline: ''
    });

    // При первом рендере загружаем список пользователей
    useEffect(() => {
        async function fetchUsers() {
            try {
                const userListS = await userList(state_in['token']);
                if (userListS?.data) {
                    // Превратим в массив опций для SelectMini
                    const arr = userListS.data.map((u: any) => ({
                        ...u,
                        value: u.id // нужно для SelectMini
                    }));
                    setUserListBox(arr);
                }
            } catch (error) {
                console.error('Ошибка при загрузке пользователей:', error);
            }
        }

        fetchUsers();
    }, [state_in]);

    // Загружаем задачи при каждом изменении activeType
    useEffect(() => {
        fetchTasks(activeType);
    }, [activeType]);

    // Загрузка задач
    async function fetchTasks(type: OnboardingType) {
        try {
            let result: any;
            if (type === 'workplace') {
                result = await getOnboardingWorkplaceTasks(state_in['token']);
            } else if (type === 'plan') {
                result = await getOnboardingPlanTasks(state_in['token']);
            } else {
                result = await getOnboardingStudyingTasks(state_in['token']);
            }

            // result.data.my_tasks - согласно вашему JSON
            const arr = result?.data || [];
            const tasksArr = arr.my_tasks || [];
            // Превратим в нужный формат
            const tasksWithCheck = tasksArr.map((item: any) => ({
                ...item,
                // checked = true, если статус «выполнено», иначе false
                checked: item.status === 'выполнено'
            }));
            setTasks(tasksWithCheck);

        } catch (error) {
            console.error('Ошибка при получении задач:', error);
            setTasks([]);
        }
    }

    /**
     * При клике на чекбокс (строка) — переключаем локально
     * и отправляем запрос (выполнено / в работе)
     */
    const handleToggleRow = async (task: ITask) => {
        // Предполагаем, если было checked = true => снимаем => статус = "в работе"
        // Если было false => ставим => "выполнено"
        const newChecked = !task.checked;
        const newStatus = newChecked ? 'выполнено' : 'в работе';

        // Сначала меняем локально
        setTasks(prev =>
            prev.map(t => t.id === task.id ? {...t, checked: newChecked, status: newStatus} : t)
        );

        // Отправляем запрос на смену статуса
        try {
            if (activeType === 'workplace') {
                await setWorkplaceStatus(task.id, newStatus, state_in['token']);
            } else if (activeType === 'plan') {
                await setPlanStatus(task.id, newStatus, state_in['token']);
            } else {
                await setStudyingStatus(task.id, newStatus, state_in['token']);
            }
        } catch (error) {
            console.error('Ошибка при обновлении статуса:', error);
            // Если надо, откатим локально
            // setTasks(prev => prev.map(t => t.id === task.id ? {...t, checked: !newChecked} : t));
        }
    };

    // «Выбрать / Снять все» - если есть хотя бы один неотмеченный => отметить все, иначе снять все
    const handleCheckAll = () => {
        const someUnchecked = tasks.some(t => !t.checked);
        const newStatus = someUnchecked ? 'выполнено' : 'в работе';
        // В этом варианте, если хотим мгновенно отправить N запросов — придётся это делать циклом.
        // Или убираем мгновенную отправку, оставляем только локальное.
        // Покажу вариант "только локальное", без запросов, чтобы не было N запросов.
        setTasks(prev => prev.map(item => ({
            ...item,
            checked: someUnchecked
        })));
    };

    // ==== Создание новой записи ====
    const handleOpenModal = () => {
        setNewTask({executor_id: '', theme: '', deadline: ''});
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSaveNewTask = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (activeType === 'workplace') {
                await createWorkplaceTask({
                    initiator_id: 5,
                    executor_id: Number(newTask.executor_id),
                    theme: newTask.theme,
                    deadline: newTask.deadline
                }, state_in['token']);
            } else if (activeType === 'plan') {
                await createPlanTask({
                    initiator_id: 5,
                    executor_id: [Number(newTask.executor_id)],
                    theme: newTask.theme,
                    period: "1 день"
                }, state_in['token']);
            } else {
                await createStudyingTask({
                    initiator_id: 5,
                    executor_id: Number(newTask.executor_id),
                    theme: newTask.theme,
                    form: "Презентация",
                    deadline: newTask.deadline,
                    comment: "коммент"
                }, state_in['token']);
            }
            setModalOpen(false);
            await fetchTasks(activeType);
        } catch (error) {
            console.error('Ошибка при создании новой задачи:', error);
        }
    };

    // Настройка колонок для TableCustom
    const columns = {
        ...(
            activeType === 'studying' && {
                id: {
                    name: '№',
                    w: 0,
                },
            }
        ),


        theme: {
            name: activeType === 'studying' ? 'Название курса' : 'Чек-лист',
            w: 12,
            option: null
        },
        ...(
            activeType === 'studying' && {
                prior: {
                    name: 'Приоритет',
                    w: 12,
                },
            }
        ),

        ...(
            activeType === 'workplace' && {
                executor_name: {
                    name: 'Исполнитель',
                    w: 12,
                    option: null
                },
            }

        ),
        ...(
            activeType === 'plan' && {
                period: {
                    name: 'Период',
                    w: 12,
                },
            }
        ),
        ...(
            activeType === 'plan' && {
                period: {
                    name: 'Период',
                    w: 12,
                },
            }
        ),
        ...(
            activeType === 'plan' && {
                executor_name: {
                    name: 'Участник',
                    w: 12,
                    option: null
                },
            }
        ),
        ...(
            activeType === 'studying' && {
                form: {
                    name: 'Форма',
                    w: 12,
                },
            }
        ),
        ...(
            activeType === 'studying' && {
                deadline: {
                    name: 'Дедлайн',
                    w: 12,
                },
            }
        ),
        ...(
            activeType === 'studying' && {
                executor_name: {
                    name: 'Спикер курса',
                    w: 12,
                },
            }
        ),
        ...(
            activeType === 'studying' && {
                comment: {
                    name: 'Комментарий',
                    w: 15,
                },
            }
        ),


        status: {
            name: 'Статус',
            w: 12,
            option: ((row) => {
                return (
                    <div className="task-option">
                        <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {row['status']} </a>
                    </div>
                )
            })
        },
        option: {
            name: '',
            w: 8,
            option: (row: ITask) => {
                return (
                    <div>
                        <input
                            type="checkbox"
                            checked={!!row.checked}
                            onChange={() => handleToggleRow(row)}
                        />
                    </div>
                );
            }
        }
    };

    return (
        <div className={'page-personal-border'}>
            <div className="page-personal-border-site-bar">
                <button
                    className={'text text-s18 ' + (activeType === 'workplace' ? 'active' : '')}
                    onClick={() => setActiveType('workplace')}
                >
                    Организация рабочего места
                </button>
                <button
                    className={'text text-s18 ' + (activeType === 'plan' ? 'active' : '')}
                    onClick={() => setActiveType('plan')}
                >
                    План онбординга
                </button>
                <button
                    className={'text text-s18 ' + (activeType === 'studying' ? 'active' : '')}
                    onClick={() => setActiveType('studying')}
                >
                    Обучение по онбордингу
                </button>
            </div>

            <div className="page-personal-border-main">
                {/*<div style={{ marginBottom: '10px' }}>*/}
                {/*    <button onClick={handleCheckAll}>*/}
                {/*        Выбрать / Снять все (локально)*/}
                {/*    </button>*/}
                {/*</div>*/}

                <TableCustom
                    whType={'rem'}
                    th={columns}
                    tr={tasks}
                />

                <div
                    onClick={handleOpenModal}
                    className="btn-add-files text text-s14"
                    style={{
                        backgroundColor: '#6418C3',
                        marginTop: '4rem',
                        width: "15rem",
                        marginLeft: "auto"
                    }}
                >
                    Добавить
                </div>
            </div>

            {/* Модалка: добавление новой записи */}
            <Modal
                isOpen={modalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Добавить новую запись"
            >
                <h2 className={'text text-s18'}>Новая запись </h2>
                <br/>
                <br/>
                <form onSubmit={handleSaveNewTask} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>

                    <label>
                        <SelectMini
                            options={userListBox}
                            placeholder="Выбрать"
                            value={newTask.executor_id}
                            name="Исполнитель"
                            onChange={(e) => setNewTask({...newTask, executor_id: e})}
                        />

                    </label>
                    <label>
                        <InputMini
                            placeholder={'Тема'}
                            full={true}
                            value={newTask.theme}
                            onChange={(e) => setNewTask({...newTask, theme: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            placeholder={'Дата дедлайна'}
                            type="date"
                            value={newTask.deadline}
                            full={true}
                            onChange={(e) => setNewTask({...newTask, deadline: e.target.value})}
                        />
                    </label>

                    <div style={{marginTop: 20}}>
                        <button type="button" onClick={handleCloseModal} style={{marginRight: 10}}>
                            Отмена
                        </button>
                        <button type="submit">Сохранить</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default Empty;
