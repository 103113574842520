import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {store} from '../redux/index';
import StateActions from '../redux/actions/StateActions';

const {REACT_APP_API_PATH} = process.env;

window.update_controle = false;

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

async function request(request, token, nativ = true) {
    let codeSave = getRandomArbitrary(0, 9000000);
    window.requestSendCode = codeSave;

    let status_code = false;
    if (window.update_controle) {
        status_code = true;
        window.update_controle = false;
    } else {
        status_code = false;
    }

    if (status_code && nativ) {
        NotificationManager.warning('запрос отправлен', 'Ожидайте идет обновление');
    }

    request = Object.assign(
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            method: 'GET',
            url: '',
        },
        request
    );

    request.url = REACT_APP_API_PATH + request.url;

    return await axios(request)
        .then((response) => {
            if (status_code && nativ) {
                NotificationManager.success('все ок', 'Запись обновлена');
            }
            return response.data;
        })
        .catch(function (error) {
            if (error?.response?.data?.errors) {
                return {error: error?.response?.data?.errors};
            }
            if (error?.message === 'Network Error') {
                error.message = 'Нет доступа к интернету';
            }
            if (error?.response?.data?.data?.error) {
                return {error: error.response.data.data.error};
            }
        });
}

// Пример получения курса по ID
export async function courseById(id) {
    const req = await request({
        url: `/courses/${id}`,
        method: 'GET',
    });
    return req;
}

export async function coursesUsers(token) {
    const req = await request({
        url: `/courses/user/list`,
        method: 'GET',
    }, token);
    return req;
}





// 1) Получить список «Организация рабочего места»
export async function getOnboardingWorkplaceTasks(token) {
    return await request({
        url: '/user/personal_account/onboarding/workplace_organization/my_tasks',
        method: 'GET'
    },token);
}

// 2) Получить список «План онбординга»
export async function getOnboardingPlanTasks(token) {
    return await request({
        url: '/user/personal_account/onboarding/plan/my_tasks',
        method: 'GET'
    },token);
}

// 3) Получить список «Обучение по онбордингу»
export async function getOnboardingStudyingTasks(token) {
    return await request({
        url: '/user/personal_account/onboarding/studying/my_tasks',
        method: 'GET'
    },token);
}

// ============== Методы смены статуса ==============

// Организация рабочего места — отметить задачу как "выполнено"
export async function setWorkplaceStatus(id, status,token) {
    return await request({
        url: '/user/personal_account/onboarding/workplace_organization/change_status',
        method: 'POST',
        data: {
            id,
            status
        }
    },token);
}

// План онбординга
export async function setPlanStatus(id, status,token) {
    return await request({
        url: '/user/personal_account/onboarding/plan/change_status',
        method: 'POST',
        data: {
            id,
            status
        }
    },token);
}

// Обучение
export async function setStudyingStatus(id, status,token) {
    return await request({
        url: '/user/personal_account/onboarding/studying/change_status',
        method: 'POST',
        data: {
            id,
            status
        }
    },token);
}



// Создать запись «Организация рабочего места»
export async function createWorkplaceTask(payload,token) {
    return await request({
        url: '/user/personal_account/onboarding/workplace_organization/create',
        method: 'POST',
        data: payload
    },token);
}

// Создать запись «План онбординга»
export async function createPlanTask(payload,token) {
    return await request({
        url: '/user/personal_account/onboarding/plan/create',
        method: 'POST',
        data: payload
    },token);
}

// Создать запись «Обучение»
export async function createStudyingTask(payload,token) {
    return await request({
        url: '/user/personal_account/onboarding/studying/create',
        method: 'POST',
        data: payload
    },token);
}



export async function updateCourseStartUser(data, token) {
    return await request({
        url: `/courses/user/start`,
        method: 'POST',
        data: data
    }, token);
}


export async function updateCourseSubmitUser(data, token) {
    return await request({
        url: `/courses/user/submit_answers`,
        method: 'POST',
        data: data
    }, token);
}

export async function courseArchive(data, token) {
    return await request({
        url: `/courses/archive`,
        method: 'POST',
        data: data
    }, token);
}

export async function updateUserNameEmail(userId, payload, token) {
    return await request({
        url: `/user/update/${userId}`,
        method: 'POST',
        data: {
            // какие-то поля, которые хотите обновить
            name: payload.name,
            email: payload.email,
            manager_id: payload.manager_id,
            company_id: payload.company_id,
        }
    }, token);
}

/**
 * Обновить (или добавить) номер телефона
 * @param userId
 * @param phoneNumber
 * @param token
 */
export async function updateUserPhone(userId, phoneNumber, token) {
    return await request({
        url: '/user/phone',
        method: 'POST',
        data: {
            user_id: userId,
            phone_numb: phoneNumber,
            action: 'add', // или 'update' / 'remove' - зависит от логики на бэке
        }
    }, token);
}

// Пример: загрузить/обновить диплом
export async function uploadUserDiploma(userId, file, token) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', String(userId));
    formData.append('action', 'add'); // или 'update'/'remove' - смотрите как на бэке

    return await request({
        url: '/user/diploma',
        method: 'POST',
        data: formData,
    }, token);
}

// Пример: загрузить/обновить удостоверение личности
export async function uploadUserIdCard(userId, file, token) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', String(userId));
    formData.append('action', 'add');

    return await request({
        url: '/user/id_card_file',
        method: 'POST',
        data: formData,
    }, token);
}

// Аналогично для резюме, job offer, сертификатов
export async function uploadUserResume(userId, file, token) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', String(userId));
    formData.append('action', 'add');

    return await request({
        url: '/user/resume',
        method: 'POST',
        data: formData,
    }, token);
}

// И т.д. для job_offer, certificate_police_clearance, certificate_075 и прочих эндпоинтов:
export async function uploadUserJobOffer(userId, file, token) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', String(userId));
    formData.append('action', 'add');

    return await request({
        url: '/user/job_offer',
        method: 'POST',
        data: formData,
    }, token);
}


/**
 * Обновить (или добавить) адрес регистрации
 */
export async function updateUserRegAddress(userId, address, token) {
    return await request({
        url: '/user/registration_address',
        method: 'POST',
        data: {
            user_id: userId,
            registration_address: address,
            action: 'add',
        }
    }, token);
}

/**
 * Обновить (или добавить) фактический адрес проживания
 */
export async function getUserResidAddress(userId, address, token) {
    return await request({
        url: '/user/residential_address',
        method: 'GET',
    }, token);
}


export async function coursesStartAdmin(data, token) {
    const formData = new FormData();

    // Обычные поля
    formData.append('name', data.name);
    formData.append('format', data.format);
    formData.append('speaker', data.speaker);
    formData.append('course_id', data.course_id);
    formData.append('start', data.start);
    formData.append('listeners[]', data.listeners);
    formData.append('deadline', data.deadline);
    formData.append('duration', data.duration);
    formData.append('priority', data.priority);
    formData.append('description', data.description);

    // Превью (обложка)
    if (data.preview) {
        formData.append('preview', data.preview);
    }

    // Массив файлов
    if (Array.isArray(data.files)) {
        data.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });
    }

    // Массив ссылок (если есть)
    if (Array.isArray(data.links)) {
        data.links.forEach((link, index) => {
            formData.append(`links[${index}]`, link);
        });
    }

    // Тестовые вопросы
    if (Array.isArray(data.questions)) {
        formData.append('questions', JSON.stringify(data.questions));
    }

    const req = await request(
        {
            url: '/courses/start',
            method: 'POST',
            data: formData,
        },
        token
    );

    return req;
}



export async function updateUserResidAddress(userId, address, token) {
    return await request({
        url: '/user/residential_address',
        method: 'POST',
        data: {
            user_id: userId,
            residential_address: address,
            action: 'add',
        }
    }, token);
}

/**
 * Обновить (или добавить) банковский счёт KZ
 */
export async function updateUserBankAccount(userId, bankAccount, token) {
    return await request({
        url: '/user/bank_account_kz',
        method: 'POST',
        data: {
            user_id: userId,
            bank_account_kz: bankAccount,
            action: 'add',
        }
    }, token);
}

export async function userList(token) {
    const req = await request({
        url: `/users/from_my_company`,
        method: 'GET',
    }, token);
    return req;
}

export async function getInfoCourse(id, token) {
    const req = await request({
        url: `/courses/info_without_answers?course_id=` + id,
        method: 'GET',
    }, token);
    return req;
}

export async function getInfoCourseAll(id, token) {
    const req = await request({
        url: `/courses/info_with_answers?course_id=` + id,
        method: 'GET',
    }, token);
    return req;
}


export async function getUserInfoPersonal(id, token) {
    const req = await request({
        url: `/user/account_info?user_id=` + id,
        method: 'GET',
    }, token);
    return req;
}


export async function getUserInfoPersonalJobReview(token) {
    const req = await request({
        url: `/courses/user/store_review`,
        method: 'GET',
    }, token);
    return req;
}


export async function getUserInfoPersonalJob(token) {
    const req = await request({
        url: `/user/personal_account/job`,
        method: 'GET',
    }, token);
    return req;
}


// Список курсов
export const coursesList = async (token) => {
    const req = await request(
        {
            url: '/courses/list',
            method: 'GET',
        },
        token
    );
    return req;
};

// ============== Ниже идут другие методы, не связанные с курсами (оставляем без изменений) ===============
export const getAllDistantionAdress = async (modal, add) => {
    const req = await request({
        url: '/order/maps/list',
        method: 'GET',
    });
    return req;
};

export const getModalRow = async (modal, add) => {
    const req = await request({
        url: '/database/' + modal + '/browse_column?hidden=true',
        method: 'GET',
    });
    return req;
};

export const getModalRowEdit = async (modal, id) => {
    const req = await request({
        url: '/database/' + modal + '/edit_column/' + id,
        method: 'GET',
    });
    return req;
};

export const getCatalogStorage = async (id) => {
    const req = await request({
        url: '/store/catalog/' + id,
        method: 'GET',
    });
    return req;
};

export const getAllOrder = async (token) => {
    const req = await request(
        {
            url: '/super_admin/get_requests_from_landing',
            method: 'GET',
        },
        token
    );
    return req;
};

export const getUserInfo = async (token) => {
    const req = await request(
        {
            url: '/user/avatar',
            method: 'POST',
        },
        token
    );
    return req;
};

export const tableListGet = async () => {
    const req = await request({
        url: '/table/list',
        method: 'GET',
    });
    return req;
};

export const getModal = async (modal, search) => {
    const req = await request({
        url: '/database/get/' + modal + search,
        method: 'GET',
    });
    return req;
};

export const getCatalogMenu = async () => {
    const req = await request({
        url: '/get_catalog_name',
        method: 'GET',
    });
    return req;
};

export const getOrderProduct = async (id) => {
    const req = await request({
        url: '/order/' + id + '/product',
        method: 'GET',
    });
    return req;
};

export const copyOrder = async (id) => {
    const req = await request({
        url: '/order/copy/' + id,
        method: 'GET',
    });
    return req;
};

export const getOrderMenu = async (id) => {
    const req = await request({
        url: '/order/' + id + '/menu',
        method: 'GET',
    });
    return req;
};

export const getOrderPersonal = async (id) => {
    const req = await request({
        url: '/order/' + id + '/person',
        method: 'GET',
    });
    return req;
};

export const getOrderMenuPos = async (id) => {
    const req = await request({
        url: '/order/' + id + '/newmenu',
        method: 'GET',
    });
    return req;
};

export const getMessagePush = async (user_id) => {
    const req = await request({
        url: '/get_messages/' + user_id,
        method: 'GET',
    });
    return req;
};

export const getOrderCalculation = async (id) => {
    const req = await request({
        url: '/order/get/' + id + '/calculation',
        method: 'GET',
    });
    return req;
};

export const getOrderServ = async (id) => {
    const req = await request({
        url: '/order/' + id + '/serv',
        method: 'GET',
    });
    return req;
};

export const getRowArenda = async (id, date_select) => {
    const req = await request({
        url: '/get/order/product/' + id + '?day=' + date_select,
        method: 'GET',
    });
    return req;
};

export const getOrder = async (search) => {
    const req = await request({
        url: '/database/get/Order' + search,
        method: 'GET',
    });
    return req;
};

export const deleteDataBase = async (form, model) => {
    const req = await request({
        url: '/database/' + model + '/delete',
        method: 'POST',
        data: form,
    });
    return req;
};

export const getCalendar = async (controls = true) => {
    const req = await request({
        url: '/calendar_get' + (controls ? '' : '?hidden=true'),
        method: 'GET',
    });
    return req;
};

export const getText = async () => {
    const req = await request({
        url: '/text/get?lang=' + store.getState().rootReducer.state.lang,
        method: 'GET',
    });
    return req;
};

export const getGaller = async (id) => {
    const req = await request({
        url: '/text/get/img?key=' + id,
        method: 'GET',
    });
    return req;
};

export const setForm = async (form) => {
    const req = await request({
        url: '/text/form/save',
        method: 'POST',
        data: form,
    });
    return req;
};

export const setText = async (name_key, content, type) => {
    const req = await request({
        url: '/text/save',
        method: 'POST',
        data: {
            name_key: name_key,
            content: content,
            type: type,
        },
    });
    return req;
};

export const getAuth = async (query) => {
    const req = await request({
        url: '/auth/login',
        method: 'POST',
        data: query,
    });
    return req;
};

export const postRegister = async (query) => {
    const req = await request({
        url: '/auth/register',
        method: 'POST',
        data: query,
    });
    return req;
};

export const sendFile = async (query) => {
    const req = await request(
        {
            url: '/update/files',
            method: 'POST',
            data: query,
        },
        '',
        'file'
    );
    return req;
};

export const bankCharge = async (query) => {
    const req = await request(
        {
            url: '/update/fix/efc',
            method: 'POST',
            data: query,
        },
        ''
    );
    return req;
};

export const infoEcp = async (query) => {
    const req = await request(
        {
            url: '/nca/info',
            method: 'POST',
            data: query,
        },
        ''
    );
    return req;
};

export const sendForm = async (query) => {
    const req = await request({
        url: '/send/form',
        method: 'POST',
        data: query,
    });
    return req;
};

export const getSingle = async (modal, id, params = '') => {
    const req = await request({
        url: `/database/get/${modal}/${id}` + params,
        method: 'GET',
    });
    return req;
};

export const updateDBOrderProduct = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'POST',
            url: `/database/orderproduct?v=2`,
            data: data,
        },
        false,
        nativ
    );
    return info;
};

export const updateDBOrderMenu = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'POST',
            url: `/database/ordermenu?v=2`,
            data: data,
        },
        false,
        nativ
    );
    return info;
};

export const updateDBOrderPersonal = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'POST',
            url: `/database/orderpersonal?v=2`,
            data: data,
        },
        false,
        nativ
    );
    return info;
};

export const updateDB = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'POST',
            url: `/database/update?v=2`,
            data: data,
        },
        false,
        nativ
    );
    return info;
};


export const saveJson = async (data, nativ = true) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'POST',
            url: `/pdf/save_json`,
            data: data,
        },
        false,
        nativ
    );
    return info;
};

export const povarGen = async (fileName, nativ = true) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'GET',
            url: `/pdf/save_json?filename=` + fileName,
        },
        false,
        nativ
    );
    return info;
};

export const sendImportFile = async (data) => {
    window.update_controle = true;
    let info = await request(
        {
            method: 'POST',
            url: `/import/pack/` + data['id'],
            data: {
                file: data['file'],
            },
        },
        false
    );
    return info;
};

// ========================= НОВАЯ ФУНКЦИЯ ДЛЯ СОЗДАНИЯ КУРСА =========================

/**
 * Создание нового курса
 *
 * Пример структуры data:
 * {
 *   name: "Название курса",
 *   format: "курс",
 *   speaker: 16,
 *   start: "2024-11-01T10:00:00",
 *   deadline: "2024-11-15T18:00:00",
 *   duration: 14,
 *   preview: File, // или null
 *   priority: "не срочно",
 *   description: "текст",
 *   files: [File, File],
 *   links: ["http://test_link.com","http://test_link2.com"],
 *   questions: [{ ... }, { ... }],
 * }
 */

export const updateCourse = async (data, token) => {
    const formData = new FormData();

    // Обычные поля
    formData.append('name', data.name);
    formData.append('format', data.format);
    formData.append('speaker', data.speaker);
    formData.append('start', data.start);
    formData.append('deadline', data.deadline);
    formData.append('duration', data.duration);
    formData.append('priority', data.priority);
    formData.append('description', data.description);

    // Превью (обложка)
    if (data.preview) {
        formData.append('preview', data.preview);
    }

    // Массив файлов
    if (Array.isArray(data.files)) {
        data.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });
    }

    // Массив ссылок (если есть)
    if (Array.isArray(data.links)) {
        data.links.forEach((link, index) => {
            formData.append(`links[${index}]`, link);
        });
    }

    // Тестовые вопросы
    if (Array.isArray(data.questions)) {
        formData.append('questions', JSON.stringify(data.questions));
    }

    const req = await request(
        {
            url: '/courses/store',
            method: 'POST',
            data: formData,
        },
        token
    );

    return req;
}


export const createCourse = async (data, token) => {
    const formData = new FormData();

    // Обычные поля
    formData.append('name', data.name);
    formData.append('format', data.format);
    formData.append('speaker', data.speaker);
    formData.append('start', data.start);
    formData.append('listeners[]', data.listeners);
    formData.append('deadline', data.deadline);
    formData.append('duration', data.duration);
    formData.append('priority', data.priority);
    formData.append('description', data.description);

    // Превью (обложка)
    if (data.preview) {
        formData.append('preview', data.preview);
    }

    // Массив файлов
    if (Array.isArray(data.files)) {
        data.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });
    }

    // Массив ссылок (если есть)
    if (Array.isArray(data.links)) {
        data.links.forEach((link, index) => {
            formData.append(`links[${index}]`, link);
        });
    }

    // Тестовые вопросы
    if (Array.isArray(data.questions)) {
        formData.append('questions', JSON.stringify(data.questions));
    }

    const req = await request(
        {
            url: '/courses/store',
            method: 'POST',
            data: formData,
        },
        token
    );

    return req;
};
