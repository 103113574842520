// @ts-ignore
import React, { useState } from 'react';
import './scss/index.scss';

// @ts-ignore
import searchImg from "./assets/icon/search.svg";
// @ts-ignore
import fileLinkImg from "./assets/icon/file_link.svg";

interface Props {
    name?: string;
    placeholder?: string;
    value?: string;
    type?: string;
    search?: boolean;
    center?: boolean;
    phLeft?: boolean;
    full?: boolean;
    onChange?: Function;
}

const Empty: React.FC<Props> = ({
                                    name,
                                    phLeft = false,
                                    value = '',
                                    placeholder,
                                    type = 'text',
                                    onChange,
                                    search = false,
                                    center = false,
                                    full = false
                                }) => {

    // Локальное состояние для хранения названия выбранного файла
    const [fileName, setFileName] = useState('');

    // Обработчик изменения файла
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
        if (e.target.files && e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        } else {
            setFileName('');
        }
    };

    return (
        <div
            className={
                "input " +
                (search ? " input-search" : "") +
                (center ? " input-center" : "") +
                (phLeft ? " input-left" : "") +
                (full ? " input-full" : "")
            }
        >
            {search && (
                <div
                    className="icon-search-input"
                    style={{ backgroundImage: `url(${searchImg})` }}
                />
            )}

            {name && (
                <div className="input-label text text-gray2 text-s14">
                    {name}
                </div>
            )}

            {type !== 'textLarge' && type === 'file' && (
                <div className="file-select">
                    <p className="text text-s14">
                        {fileName || 'Файл не выбран'}
                    </p>

                    <input
                        onChange={handleFileChange}
                        type="file"
                        placeholder={placeholder}
                        className={
                            "input-in file-absolut text text-s14" +
                            (type === 'file' ? " input-in-file" : "")
                        }
                    />

                    <img src={fileLinkImg} alt="file icon" />
                </div>
            )}

            {type !== 'textLarge' && type !== 'file' && (
                <input
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    className={
                        "input-in text text-s14" +
                        (type === 'file' ? " input-in-file" : "")
                    }
                />
            )}

            {type === 'textLarge' && (
                <textarea
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    placeholder={placeholder}
                    className="input-in text text-s14"
                    value={value}
                />
            )}
        </div>
    );
};

export default Empty;
