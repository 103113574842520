import './scss/index.scss';

// @ts-ignore
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
// @ts-ignore
import print from "../Drop/assets/icon/print.svg";
// @ts-ignore
import upload from "../Drop/assets/icon/upload.svg";
// @ts-ignore
import donwload from "../Drop/assets/icon/donwload.svg";
import Drop from "../Drop";

import Modal from 'react-modal';

Modal.setAppElement('#root');

// Импортируем все нужные запросы
import {
    getUserInfoPersonal,
    getUserInfoPersonalJob,
    // Текстовые обновления
    updateUserNameEmail,
    updateUserPhone,
    updateUserRegAddress,
    updateUserResidAddress,
    updateUserBankAccount,
    // Загрузка файлов
    uploadUserIdCard,
    uploadUserDiploma,
    uploadUserResume,
    userList,
    uploadUserJobOffer,
} from '../../../../services';

// Редаксовский slice для получения user_id, token и т.д.
import StateSlices from "../../../../redux/slices/State";
import InputMini from "../../../../components/InputMini";

const Empty: React.FC = () => {
    const navigate = useNavigate();
    const state_in = useSelector(StateSlices);

    // Данные о пользователе (личные)
    const [userPersonal, setUserPersonal] = useState<any>(null);
    // Данные о работе
    const [userJob, setUserJob] = useState<any>(null);

    // ===== Личная информация: управление модалкой =====
    const [personalModalOpen, setPersonalModalOpen] = useState(false);
    // Поля для личной инфы
    const [personalForm, setPersonalForm] = useState({
        name: '',
        email: '',
        phone: '',
        residential_address: '',
        bank_account_kz: ''
    });

    // ===== Рабочая информация: управление модалкой =====
    const [jobModalOpen, setJobModalOpen] = useState(false);
    // Поля для рабочей инфы
    const [jobForm, setJobForm] = useState({
        employment_date: '',
        corporate_email: '',
        contacts: ''
    });

    // Получаем user_id и token
    const userId = state_in['user_id'];
    const token = state_in['token'];

    // При загрузке компонента, тянем данные
    useEffect(() => {
        async function fetchData() {
            try {
                const personalData = await getUserInfoPersonal(userId, token);

                let personalSave = personalData?.data;
                setUserPersonal(personalSave);

                const jobData = await getUserInfoPersonalJob(token);
                setUserJob(jobData?.data);
            } catch (err) {
                console.error('Ошибка при загрузке данных пользователя:', err);
            }
        }

        if (userId && token) {
            fetchData();
        }
    }, [userId, token]);

    // ---------------------------------------------------------------------------------
    // Открытие модалки «Личная информация» (через editOn={() => setPersonalModalOpen(true)})
    // ---------------------------------------------------------------------------------
    const handlePersonalEdit = () => {
        setPersonalForm({
            name: userPersonal?.name || '',
            email: userPersonal?.email || '',
            phone: userPersonal?.phone_numb || '',
            residential_address: userPersonal?.residential_address || '',
            bank_account_kz: userPersonal?.bank_account_kz || ''
        });
        setPersonalModalOpen(true);
    };

    // Сохранение изменений «Личная информация»
    const handlePersonalSave = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            // 1) Имя + email (возможно, manager_id, company_id - подставьте, если нужно)
            await updateUserNameEmail(
                Number(userId),
                {
                    name: personalForm.name,
                    email: personalForm.email,
                    company_id: 2,
                },
                token
            );

            // 2) Телефон
            if (personalForm.phone) {
                await updateUserPhone(Number(userId), personalForm.phone, token);
            }

            // 3) Адрес проживания (residential_address)
            if (personalForm.residential_address) {
                await updateUserResidAddress(Number(userId), personalForm.residential_address, token);
            }

            // 4) Счёт KZ
            if (personalForm.bank_account_kz) {
                await updateUserBankAccount(Number(userId), personalForm.bank_account_kz, token);
            }

            // После обновления, повторно получим userPersonal
            const personalData = await getUserInfoPersonal(userId, token);
            setUserPersonal(personalData?.data);

            // Закрыть модалку
            setPersonalModalOpen(false);

        } catch (err) {
            console.error('Ошибка сохранения личной информации:', err);
        }
    };

    // ---------------------------------------------------------------------------------
    // Открытие модалки «Рабочая информация»
    // ---------------------------------------------------------------------------------
    const handleJobEdit = () => {
        setJobForm({
            employment_date: userJob?.employment_date || '',
            corporate_email: userJob?.corporate_email || '',
            contacts: userJob?.contacts || ''
        });
        setJobModalOpen(true);
    };

    // Сохранение изменений «Рабочая информация»
    // (здесь у вас могут быть отдельные методы API, например updateJob(...) —
    //  но в вашем Postman JSON такой логики не видно, возможно jobInfo в другом месте)
    const handleJobSave = async (e: React.FormEvent) => {
        e.preventDefault();


        try {

            const company_name = await userList(token);
            await updateUserNameEmail(
                Number(userId),
                {
                    // Положим эти поля на бэке обрабатываются
                    employment_date: jobForm.employment_date,
                    corporate_email: jobForm.corporate_email,
                    contacts: jobForm.contacts,
                    company_id: 2,
                },
                token
            );

            // После обновления перезапрашиваем userJob
            const jobData = await getUserInfoPersonalJob(token);
            setUserJob(jobData?.data);

            // Закрываем модалку
            setJobModalOpen(false);

        } catch (err) {
            console.error('Ошибка сохранения рабочей информации:', err);
        }
    };

    // Функция загрузки удостоверения личности
    const handleUploadIdCard = async (file: File) => {
        try {
            await uploadUserIdCard(Number(userId), file, token);
            // Обновляем userPersonal
            const personalData = await getUserInfoPersonal(userId, token);
            setUserPersonal(personalData?.data);
        } catch (err) {
            console.error('Ошибка при загрузке удостоверения личности:', err);
        }
    };

    // Функция загрузки диплома
    const handleUploadDiploma = async (file: File) => {
        try {
            await uploadUserDiploma(Number(userId), file, token);
            // Обновляем userPersonal
            const personalData = await getUserInfoPersonal(userId, token);
            setUserPersonal(personalData?.data);
        } catch (err) {
            console.error('Ошибка при загрузке диплома:', err);
        }
    };

    // Функция загрузки резюме
    const handleUploadResume = async (file: File) => {
        try {
            await uploadUserResume(Number(userId), file, token);
            // Обновляем userJob
            const jobData = await getUserInfoPersonalJob(token);
            setUserJob(jobData?.data);
        } catch (err) {
            console.error('Ошибка при загрузке резюме:', err);
        }
    };

    // Функция загрузки job offer
    const handleUploadJobOffer = async (file: File) => {
        try {
            await uploadUserJobOffer(Number(userId), file, token);
            // Обновляем userJob
            const jobData = await getUserInfoPersonalJob(token);
            setUserJob(jobData?.data);
        } catch (err) {
            console.error('Ошибка при загрузке Job Offer', err);
        }
    };

    return (
        <div className="personal-my">
            {/* -------------------------------------------------------- */}
            {/* 1) Личная информация */}
            {/* -------------------------------------------------------- */}
            <Drop
                name={'Личная информация'}
                // При нажатии на кнопку редактирования
                editOn={() => {
                    handlePersonalEdit();
                }}
                drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">ФИО</div>
                                <div className="drop-item-value text text-gray text-s16">
                                    {userPersonal?.name || '—'}
                                </div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">E-mail</div>
                                <div className="drop-item-value text text-gray text-s16">
                                    {userPersonal?.email || '—'}
                                </div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Номер телефона</div>
                                <div className="drop-item-value text text-gray text-s16">
                                    {userPersonal?.phone_numb || '—'}
                                </div>
                            </div>

                            <div className="drop-item">
                                <div className="drop-item-name">Удостоверение личности</div>
                                <div className="drop-item-value center-icon text text-gray text-s16">

                                    {/* download: если бэкенд возвращает userPersonal.id_card_url */}
                                    {userPersonal?.id_card_file && (
                                        <>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.id_card_file} target="_blank" rel="noreferrer">
                                                <img className={'icon-about'} src={print} alt="print"/>
                                            </a>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.id_card_file} download={true}
                                               rel="noreferrer">
                                                <img className={'icon-about'} src={donwload} alt="download"/>
                                            </a>
                                        </>
                                    )}
                                    {/* Загрузить (заменить) удостоверение */}
                                    <label style={{cursor: 'pointer'}}>
                                        <img className={'icon-about'} src={upload} alt="upload"/>
                                        <input
                                            type="file"
                                            style={{display: 'none'}}
                                            onChange={async (e) => {
                                                const file = e.target.files?.[0];
                                                if (file) {
                                                    await handleUploadIdCard(file);
                                                }
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="drop-item">
                                <div className="drop-item-name">Диплом</div>
                                <div className="drop-item-value center-icon text text-gray text-s16">


                                    {userPersonal?.diploma && (
                                        <>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.diploma} target="_blank" rel="noreferrer">
                                                <img className={'icon-about'} src={print} alt="print"/>
                                            </a>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.diploma} download={true}
                                               rel="noreferrer">
                                                <img className={'icon-about'} src={donwload} alt="download"/>
                                            </a>
                                        </>
                                    )}

                                    {/* Загрузить (заменить) диплом */}
                                    <label style={{cursor: 'pointer'}}>
                                        <img className={'icon-about'} src={upload} alt="upload"/>
                                        <input
                                            type="file"
                                            style={{display: 'none'}}
                                            onChange={async (e) => {
                                                const file = e.target.files?.[0];
                                                if (file) {
                                                    await handleUploadDiploma(file);
                                                }
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="drop-item">
                                <div className="drop-item-name">Адрес</div>
                                <div className="drop-item-value text text-gray text-s16">
                                    {userPersonal?.residential_address || 'г. Алматы, район Жетысу, дом 15'}
                                </div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Счет KZ</div>
                                <div className="drop-item-value text text-gray text-s16">
                                    {userPersonal?.bank_account_kz || '0084763TFYG82910KZ'}
                                </div>
                            </div>
                        </div>
                    );
                }}
            />

            {/* -------------------------------------------------------- */}
            {/* 2) Рабочая информация */}
            {/* -------------------------------------------------------- */}
            <Drop
                name={'Рабочая информация'}
                // При нажатии на кнопку редактирования
                edit={false}
                drop={() => {
                    return (
                        <div className={'drop-list'}>
                            {/* Резюме */}
                            <div className="drop-item">
                                <div className="drop-item-name">Резюме</div>
                                <div className="drop-item-value center-icon text text-gray text-s16">

                                    {userPersonal?.resume && (
                                        <>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.resume} target="_blank" rel="noreferrer">
                                                <img className={'icon-about'} src={print} alt="print"/>
                                            </a>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.resume} download={true}
                                               rel="noreferrer">
                                                <img className={'icon-about'} src={donwload} alt="download"/>
                                            </a>
                                        </>
                                    )}
                                    {/* Загрузить (заменить) резюме */}
                                    <label style={{cursor: 'pointer'}}>
                                        <img className={'icon-about'} src={upload} alt="upload"/>
                                        <input
                                            type="file"
                                            style={{display: 'none'}}
                                            onChange={async (e) => {
                                                const file = e.target.files?.[0];
                                                if (file) {
                                                    await handleUploadResume(file);
                                                }
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Job Offer */}
                            <div className="drop-item">
                                <div className="drop-item-name">Job Offer</div>
                                <div className="drop-item-value center-icon text text-gray text-s16">
                                    {userPersonal?.job_offer && (
                                        <>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.job_offer} target="_blank" rel="noreferrer">
                                                <img className={'icon-about'} src={print} alt="print"/>
                                            </a>
                                            <a href={'https://back.hrbasic.kz/storage/'+userPersonal.job_offer} download={true}
                                               rel="noreferrer">
                                                <img className={'icon-about'} src={donwload} alt="download"/>
                                            </a>
                                        </>
                                    )}
                                    {/* Загрузить (заменить) Job Offer */}
                                    <label style={{cursor: 'pointer'}}>
                                        <img className={'icon-about'} src={upload} alt="upload"/>
                                        <input
                                            type="file"
                                            style={{display: 'none'}}
                                            onChange={async (e) => {
                                                const file = e.target.files?.[0];
                                                if (file) {
                                                    await handleUploadJobOffer(file);
                                                }
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Пример использования ответа userJob */}
                            <div className="drop-item">
                                <div className="drop-item-name">Дата приема</div>
                                <div className="drop-item-value text text-gray text-s16">
                                    {userJob?.employment_date ?? ''}
                                </div>
                            </div>
                            {/*<div className="drop-item">*/}
                            {/*    <div className="drop-item-name">Сертификаты</div>*/}
                            {/*    <div className="drop-item-value text text-gray text-s16">*/}
                            {/*        <img className={'icon-about'} src={print} alt="print"/>*/}
                            {/*        <img className={'icon-about'} src={donwload} alt="download"/>*/}
                            {/*        /!* Аналогично можно добавить upload для сертификатов, если есть эндпоинт *!/*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    );
                }}
            />

            {/* -------------------------------------------------------- */}
            {/* Модалка для редактирования Личной информации */}
            {/* -------------------------------------------------------- */}
            <Modal
                isOpen={personalModalOpen}
                onRequestClose={() => setPersonalModalOpen(false)}
                contentLabel="Редактировать личную информацию"
            >
                <h2 className={'text text-s22'}>Редактирование личной информации</h2>
                <br/>
                <br/>
                <form onSubmit={handlePersonalSave} style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    <label>
                        <InputMini
                            name="ФИО "
                            placeholder=""
                            full
                            value={personalForm.name}
                            onChange={(e) => setPersonalForm({...personalForm, name: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            name="E-mail "
                            placeholder=""
                            full
                            value={personalForm.email}
                            onChange={(e) => setPersonalForm({...personalForm, email: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            name="Телефон "
                            placeholder=""
                            full
                            value={personalForm.phone}
                            onChange={(e) => setPersonalForm({...personalForm, phone: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            name="Адрес проживания "
                            placeholder=""
                            full
                            value={personalForm.residential_address}
                            onChange={(e) => setPersonalForm({...personalForm, residential_address: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            name="Счёт KZ:"
                            placeholder=""
                            full
                            value={personalForm.bank_account_kz}
                            onChange={(e) => setPersonalForm({...personalForm, bank_account_kz: e.target.value})}
                        />
                    </label>

                    <div style={{marginTop: 20}}>
                        <button type="button" onClick={() => setPersonalModalOpen(false)} style={{marginRight: 10}}>
                            Отмена
                        </button>
                        <button type="submit">
                            Сохранить
                        </button>
                    </div>
                </form>
            </Modal>

            {/* -------------------------------------------------------- */}
            {/* Модалка для редактирования Рабочей информации */}
            {/* -------------------------------------------------------- */}
            <Modal
                isOpen={jobModalOpen}
                onRequestClose={() => setJobModalOpen(false)}
                contentLabel="Редактировать рабочую информацию"
            >
                <h2>Редактирование рабочей информации</h2>
                <form onSubmit={handleJobSave} style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    <label>
                        <InputMini
                            name="Дата приема"
                            placeholder=""
                            full
                            value={jobForm.employment_date}
                            onChange={(e) => setJobForm({...jobForm, employment_date: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            name="Корпоративная почта"
                            placeholder=""
                            full
                            value={jobForm.corporate_email}
                            onChange={(e) => setJobForm({...jobForm, corporate_email: e.target.value})}
                        />
                    </label>
                    <label>
                        <InputMini
                            name="Контакты"
                            placeholder=""
                            full
                            value={jobForm.contacts}
                            onChange={(e) => setJobForm({...jobForm, contacts: e.target.value})}
                        />
                    </label>

                    <div style={{marginTop: 20}}>
                        <button type="button" onClick={() => setJobModalOpen(false)} style={{marginRight: 10}}>
                            Отмена
                        </button>
                        <button type="submit">
                            Сохранить
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default Empty;
