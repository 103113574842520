import './scss/index.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StateSlices from '../../redux/slices/State';
import Layout from '../../components/Layout';

import Drop from '../personal/components/Drop';
import InputMini from '../../components/InputMini';
import backImg from './assets/icon/back.svg';
import SelectMini from '../../components/SelectMini';

// Импорт сервиса (из файла services/index.js или request.js)
import {
    getInfoCourse,
    updateCourseStartUser,
    updateCourseSubmitUser
} from '../../services';

import { useNavigate, useParams } from "react-router-dom";

// ================== Компонент ================== //

export default function AboutRoute() {
    const state_in = useSelector(StateSlices);
    const navigate = useNavigate();
    const { id } = useParams(); // берем ID курса из URL

    const [course, setCourse] = useState(null);  // здесь будем хранить объект курса
    // Стейт для хранения выбранных ответов, где ключ = question.id, значение = массив answer.id
    const [selectedAnswers, setSelectedAnswers] = useState({});

    useEffect(() => {
        async function fetchCourseAndStart() {
            try {
                if (!id) return; // если нет id в URL, выходим
                // 1) Получаем инфу о курсе
                const data = await getInfoCourse(id, state_in['token']);
                setCourse(data.data);

                // 2) «Запуск курса», если нужно сразу отмечать на сервере, что пользователь начал курс
                //    Если не нужно при каждом открытии, уберите этот вызов.
                await updateCourseStartUser({ course_id: id }, state_in['token']);

            } catch (error) {
                console.error('Ошибка при загрузке курса:', error);
            }
        }

        fetchCourseAndStart();
    }, [id, state_in]);

    // Функция смены выбранных ответов
    // questionId: string (или number), answerId: number
    // checked: boolean (добавить/убрать из массива)
    const handleCheckboxChange = (
        questionId,
        answerId,
        checked
    ) => {
        setSelectedAnswers((prev) => {
            const questionKey = String(questionId);
            const oldAnswers = prev[questionKey] || [];

            let newAnswers;
            if (checked) {
                // добавляем answerId (если не дубль)
                newAnswers = [...oldAnswers, answerId];
            } else {
                // убираем answerId
                newAnswers = oldAnswers.filter((a) => a !== answerId);
            }

            return {
                ...prev,
                [questionKey]: newAnswers,
            };
        });
    };

    // По клику "Завершить тест" собираем answers и вызываем updateCourseSubmitUser
    const handleFinishTest = async () => {
        if (!id) return;

        // Собираем массив вида:
        // [
        //   { question_id: 11, answer_id: 25 },
        //   { question_id: 11, answer_id: 26 },
        //   { question_id: 12, answer_id: 29 },
        //   ...
        // ]
        const answersArr= [];

        if (course?.tests) {
            course.tests.forEach((q) => {
                const questionId = q.id;
                const chosenAnswers = selectedAnswers[String(questionId)] || [];
                chosenAnswers.forEach((answerId) => {
                    answersArr.push({
                        question_id: questionId,
                        answer_id: answerId,
                    });
                });
            });
        }

        try {
            // вызываем endpoint
            const payload = {
                course_id: id,
                answers: answersArr,
            };
            await updateCourseSubmitUser(payload, state_in['token']);

            // возможно, после успешного сабмита - редирект или показать уведомление
            console.log('Успешно отправлены ответы', answersArr);
            navigate('/training');
            alert('Тест завершён!');
            // navigate('/somewhere'); // если нужно

        } catch (err) {
            console.error('Ошибка при отправке ответов:', err);
        }
    };

    return (
        <Layout
            headTitle={
                <div className="text-head-box">
                    <span style={{ width: '18.5rem' }}></span>
                    <p className="text text-gray text-s20">Главное меню</p>
                    <span style={{ width: '1rem' }}></span>
                    <p className="text text-gray text-s20">/</p>
                    <span style={{ width: '1rem' }}></span>
                    <p className="text text-gray text-s20">Обучение</p>
                    <span style={{ width: '1rem' }}></span>
                    <p className="text text-gray text-s20">/</p>
                    <span style={{ width: '1rem' }}></span>
                    <p className="text text-un text-bly text-s20">{course?.name}</p>
                </div>
            }
        >
            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className="text text-s36">Создать курсы</b>
                    </div>
                </div>
            </div>

            <div className="training-white">
                <img
                    onClick={() => {
                        navigate('/training');
                    }}
                    className="img-back-btn"
                    src={backImg}
                    alt="back-btn"
                />

                {/* -- КОРТКАЯ ИНФА О КУРСЕ -- */}
                <div className="course-page">
                    <div className="course-page-head">
                        <div className="course-page-img">
                            {course?.preview && <img src={course.preview} alt="Course Preview" />}
                        </div>
                        <div className="course-page-text">
                            <p className={'text text-s36'}>{course?.name}</p>
                            <p className={'text text-s14'}>{course?.format}</p>
                            <p className={'text text-s14'}>Длительность: {course?.duration} дней</p>
                            <p className={'text text-s14'}>Начало: {course?.start}</p>
                            <p className={'text text-s14'}>Дедлайн: {course?.deadline}</p>
                            <p className={'text text-s14'}>Спикер: {course?.speaker}</p>
                        </div>
                    </div>

                    <div className="course-page-body">
                        <p className={'text text-s24'}>Описание</p>
                        <br />
                        <p className={'text text-s16'}>
                            {course?.description}
                        </p>
                    </div>

                    {/* -- ВОПРОСЫ И ОТВЕТЫ -- */}
                    {
                        course?.tests && course.tests.map((testItem, index) => (
                            <div className="course-page-aw" key={testItem?.id}>
                                <div className="course-page-aw-title text text-s16">
                                    {index + 1}. {testItem?.question}
                                </div>
                                <div className="course-page-aw-list">
                                    {testItem?.answers && testItem.answers.map((ans, ansIndex) => {
                                        // буква a, b, c...
                                        const alphabetLetter = String.fromCharCode(97 + ansIndex);
                                        // проверяем, выбрал ли пользователь этот ans.id
                                        const questionIdStr = String(testItem.id);
                                        const currentSelected = selectedAnswers[questionIdStr] || [];
                                        const isChecked = currentSelected.includes(ans.id);

                                        return (
                                            <div key={ans?.id} className="sw-item">
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={(e) => handleCheckboxChange(
                                                        testItem.id,
                                                        ans.id,
                                                        e.target.checked
                                                    )}
                                                />
                                                <p className={'text text-s16'}>
                                                    {alphabetLetter}) {ans?.text}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))
                    }

                </div>

                {/* -- КНОПКА "Завершить тест" -- */}
                <div className="center-flex">
                    <div
                        className="btn-add-files text text-s14"
                        style={{ backgroundColor: '#6418C3', marginTop: '1rem' }}
                        onClick={handleFinishTest}
                    >
                        Завершить тест
                    </div>
                </div>
            </div>
        </Layout>
    );
}
