import './scss/index.scss';
// @ts-ignore
import edit from "./assets/icon/edit.svg";

interface Props {
    name: string;
}

import {coursesList, coursesUsers, userList} from "../../services";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TableCustom from "../TableCustom";

const Empty: React.FC<Props> = ({name}) => {

    const state_in = useSelector(StateSlices);
    const [curses, setCurses] = useState([]);
    const [cursesAll, setCursesAll] = useState([]);
    const [userListBox, setUserListBox] = useState([]);
    const navigate = useNavigate();

    async function getAllCurse() {
        setCursesAll((await coursesList(state_in['token'])).data);
        setCurses((await coursesUsers(state_in['token'])).data);

        const userListS = await userList(state_in['token']);
        setUserListBox(
            userListS.data.map((userMap) => ({
                ...userMap,
                value: userMap.id
            }))
        );
    }

    useEffect(() => {

        getAllCurse();


    }, []);

    return (
        <div className={'curse-list'}>


            <TableCustom
                whType={'rem'}
                th={{
                    user_id: {
                        name: 'ФИО сотрудника',
                        w: 12,
                        option: ((row) => {

                            let userListBoxInfo = userListBox.filter((userListBoxFilter) => {
                                return userListBoxFilter.id == row.user_id;
                            });

                            let name = "";
                            if (userListBoxInfo.length > 0) {
                                name = userListBoxInfo[0].name;
                            }
                            return (
                                <div className={'text text-s12'}>
                                    {name}
                                </div>
                            )
                        })
                    },


                    course_id: {
                        name: 'Название курса',
                        w: 12,
                        option: ((row) => {

                            let userListBoxInfo = cursesAll.filter((userListBoxFilter) => {
                                return userListBoxFilter.id == row.course_id;
                            });
                            let name = "";
                            if (userListBoxInfo.length > 0) {
                                name = userListBoxInfo[0].name;
                            }
                            return (
                                <div className={'text text-s12'}>
                                    {name}
                                </div>
                            )
                        })
                    },
                    started_at: {
                        name: 'Начало',
                        w: 12,
                        option: null
                    },
                    completed_at: {
                        name: 'Пройден',
                        w: 12,
                        option: null
                    },
                    popytok: {
                        name: 'Попытки',
                        w: 12,
                        option: ((row) => {
                            return (
                                <div className={'text text-s12'}>

                                </div>
                            )
                        })
                    },
                    status: {
                        name: 'Статус',
                        w: 12,
                        option: null
                    },
                    score: {
                        name: 'Результат',
                        w: 12,
                        option: ((row) => {
                            return (
                                <div className={'text text-s12'}>
                                    {row.score} / 100
                                </div>
                            )
                        })
                    },
                }}
                tr={
                    curses
                }
            />
        </div>
    );

}

export default Empty;