import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import current_event from "./assets/icon/current_event.svg";
import online_event from "./assets/icon/online_event.svg";
import next_event from "./assets/icon/next_event.svg";

import tash_filter from "./assets/icon/tash_filter.svg";

import bk_img from "./assets/img/bk_img.png";

import post_edit from "./assets/icon/post_edit.svg";


import calendar from "./assets/icon/calendar.svg";
import clock from "./assets/icon/clock.svg";

import setting from "./assets/icon/setting.svg";


import color_people from "./assets/icon/fluent-emoji-color_people-home-32.svg";
import emoji_airplane from "./assets/icon/fluent-emoji_airplane.svg";
import emoji_beach from "./assets/icon/fluent-emoji_beach-with-umbrella.svg";
import emoji_dollar from "./assets/icon/fluent-emoji_dollar-banknote.svg";
import emoji_stethoscope from "./assets/icon/fluent-emoji_stethoscope.svg";

import user from "./assets/img/user.png";
import arena from "./assets/img/arena.png";
import hose1 from "./assets/img/race-hose1.png";


import Calendar from "../../components/Calendar";

import user_img from "../../views/personal/assets/img/user.png";

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [step, setStep] = useState(0);

    const [loader, setLoader] = useState(1);


    useEffect(() => {

    }, [])


    const calculateTimeLeft = () => {
        const difference = +new Date('2025-01-20') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const formatTime = (value) => String(value).padStart(2, '0');

    const navigate = useNavigate();

    return (
        <Layout headTitle={(
            <>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Главное меню
                </p>
            </>
        )}>
            <div class="console">
                <div class="console-left">
                    <div class="console-name">
                        <b className={'text text-s24'}>
                            Доброе утро, Аружан
                        </b>
                    </div>

                    <div class="console-event">
                        <div class="console-event-item console-event-current">

                            <div className="console-event_info">
                                <div class="console-event-icon"
                                     style={{backgroundImage: "url('" + current_event + "')"}}></div>

                                <p class={'console-event-name text text-s16'}>
                                    Мероприятия компании
                                </p>
                                <p class={'console-event-content text text-s16'}>
                                    <b>День рождение компании</b>
                                </p>
                            </div>
                            <div className="console-event-timers">
                                <div className="countdown">
                                    <div className="countdown-item">
                                        <span className="time text text-s16">{formatTime(timeLeft.days)}</span>
                                        <span className="label text text-s10">день</span>
                                    </div>
                                    <div className="countdown-item">
                                        <span className="time text text-s16">{formatTime(timeLeft.hours)}</span>
                                        <span className="label text text-s10">часов</span>
                                    </div>
                                    <div className="countdown-item">
                                        <span className="time  text text-s16">{formatTime(timeLeft.minutes)}</span>
                                        <span className="label text text-s10">мин</span>
                                    </div>
                                    <div className="countdown-item">
                                        <span className="time text text-s16">{formatTime(timeLeft.seconds)}</span>
                                        <span className="label text text-s10">сек</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="console-event-item console-event-next">
                            <div class="console-event-icon"
                                 style={{backgroundImage: "url('" + next_event + "')"}}></div>
                            <p class={'console-event-name text text-s16'}>
                                Дни рождения
                            </p>
                            <p class={'console-event-content text text-s16'}>
                                <b>2 сотрудника</b>
                            </p>
                            <p className={'box-event-list-user'}>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                            </p>
                        </div>
                        <div class="console-event-item console-event-online">
                            <div class="console-event-icon"
                                 style={{backgroundImage: "url('" + online_event + "')"}}></div>
                            <p class={'console-event-name text text-s16'}>
                                Онлайн
                            </p>
                            <p class={'console-event-content text text-s16'}>
                                <b>5 сотрудника</b>
                            </p>
                            <p className={'box-event-list-user'}>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                                <span className="user-list-event-icon"
                                      style={{backgroundImage: "url(" + user_img + ")"}}></span>
                            </p>
                        </div>
                    </div>
                    <div class="console-post">

                        <div class="console-post-big" style={{backgroundImage: "url(" + bk_img + ")"}}>

                            <div class="console-post-mini-text">
                                <div class="console-post-mini-date text text-s18">
                                    13 Октября 2024
                                </div>
                                <div class="console-post-mini-title text text-s34">
                                    Нам дают доступ к фитнес-залу
                                </div>

                            </div>

                        </div>
                        <div class="console-post-list">
                            <div class="console-post-list-title">
                                <b className={'text text-s24'}>
                                    Новости
                                </b>

                                <a className={'console-post-list-create text text-s12'}>Написать новый <img
                                    className={'icon-mini'} src={post_edit}/> </a>
                            </div>
                            <div class="console-post-mini">
                                <div class="console-post-mini-img" style={{backgroundImage: "url(" + hose1 + ")"}}>

                                </div>
                                <div class="console-post-mini-text">
                                    <div class="console-post-mini-date text text-s10">
                                        13 Октября 2024
                                    </div>
                                    <div class="console-post-mini-title text text-s18">
                                        Скачки среди сотрудников, развиваем дух
                                    </div>
                                    <div class="console-post-mini-title text text-s10">
                                        Мы решили развивать наш патриотический дух и зовем всех на скачки поболеть за
                                        наших ребят
                                    </div>
                                </div>
                            </div>
                            <div class="console-post-mini">
                                <div class="console-post-mini-img" style={{backgroundImage: "url(" + arena + ")"}}>
                                </div>
                                <div class="console-post-mini-text">
                                    <div class="console-post-mini-date text text-s10">
                                        10 Октября 2024
                                    </div>
                                    <div class="console-post-mini-title text text-s18">
                                        Завтра бой Головкина в городе Амстердам
                                    </div>
                                    <div class="console-post-mini-title text text-s10">
                                        Головкин решил сыграть свой последний бой в городе Амстердам. Его оппонентом
                                        будет Патталов, он уже выигрывал все свои бои и получил звание WTF
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="console-task">

                        <div class="console-task-head">
                            <p class={'text text-s24'}>
                                <b>Задачи</b>
                            </p>

                            <a className={'console-post-list-create console-post-list-create-bly text text-s12'}>Отфильтровать <img
                                className={'icon-mini'} src={tash_filter}/> </a>
                        </div>
                        <div class="task-box">
                            {
                                [
                                    ['У вас есть невыполненные задание', '1 мин', 'Завершить', ''],
                                    ['Отправил запрос на получение выходного для вашего утверждения', '1 дн.', 'Подтвердить', 'Работник'],
                                    ['У вас есть невыполненные задание', '2 дня', 'Завершить', ''],
                                    ['Отправил запрос на получение выходного для вашего утверждения', '1 дн.', 'Подтвердить', 'Работник'],
                                    ['У вас есть невыполненные задание', '2 дня', 'Завершить', ''],
                                ].map((taskListMap) => {
                                    return (
                                        <div class="task">
                                            <div class="task-img" style={{backgroundImage: "url(" + user + ")"}}>

                                            </div>
                                            <div class="task-text">
                                                {
                                                    taskListMap[3] && (
                                                        <p class={'text text-gray text-s16'} style={{marginRight: "1rem"}}>
                                                            {taskListMap[3]}
                                                        </p>
                                                    )
                                                }

                                                <p class={'text text-s16'}>
                                                    <b>  {taskListMap[0]}</b>
                                                </p>
                                                <p style={{margin: "0 1.4rem"}}></p>
                                                <p class={'text text-s16 text-gray2'}>
                                                    {taskListMap[1]}
                                                </p>
                                            </div>
                                            <div class="task-option">
                                                <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {taskListMap[2]} </a>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div class="console-right">
                    <Calendar/>

                    <div class="console-task">

                        <div class="console-task-head console-task-head-wrap">
                            <div class="console-task-head-bord">
                                <p class={'text text-s24'}>
                                    <b>Расписание</b>
                                </p>
                                <img className={'icon-mini'} src={setting}/>
                            </div>

                            <div class="console-task-head-date">
                                <p class={'text text-gray text-s16'}>
                                    Среда Октябрь 17, 2024
                                </p>
                            </div>

                        </div>
                        <div class="task-box">
                            {['', '', ''].map(() => {
                                return (
                                    <div class="task task-border">
                                        <div class="task-img">

                                        </div>
                                        <div class="task-text task-text-flex">
                                            <p class={'text text-s18'}>
                                                <b>Встреча с Создающим отд...</b>
                                            </p>
                                            <p class={'task-text-date'}>
                                        <span class={'text text-s12'}>
                                           <img src={calendar}/> Октябрь 17, 2024
                                        </span>
                                                <span class={'text text-s12'}>
                                          <img src={clock}/>  11.00 - 12.00
                                        </span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}

                            <div class="task-box-more">
                                <div class="btn btn-def text text-s16">
                                    Смотреть все
                                </div>
                            </div>
                        </div>


                        <div class="task-box-more">
                            <div class="btn btn-leave text text-s16">
                                <span class={'text text-s24'}>0</span> <br/>
                                Сегодня не в офисе
                            </div>
                        </div>

                        <div class="leave-box">
                            <div class="leave-info">
                                <img className={'icon-leave'} src={color_people}/>
                                <div class="leave-info-num text text-s16">0</div>
                            </div>
                            <div class="leave-info">
                                <img className={'icon-leave'} src={emoji_airplane}/>
                                <div class="leave-info-num text text-s16">0</div>
                            </div>
                            <div class="leave-info">
                                <img className={'icon-leave'} src={emoji_beach}/>
                                <div class="leave-info-num text text-s16">0</div>
                            </div>
                            <br/>
                            <div class="leave-info">
                                <img className={'icon-leave'} src={emoji_dollar}/>
                                <div class="leave-info-num text text-s16">0</div>
                            </div>
                            <div class="leave-info">
                                <img className={'icon-leave'} src={emoji_stethoscope}/>
                                <div class="leave-info-num text text-s16">0</div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

        </Layout>
    );


}