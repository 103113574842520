import './scss/index.scss';
// @ts-ignore
import edit from "./assets/icon/edit.svg";

interface Props {
    name: string;
}

import {coursesList} from "../../services";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Empty: React.FC<Props> = ({name}) => {

    const state_in = useSelector(StateSlices);
    const [curses, setCurses] = useState([]);
    const navigate = useNavigate();

    async function getAllCurse() {

        setCurses((await coursesList(state_in['token'])).data);
    }

    useEffect(() => {

        getAllCurse();


    }, []);

    return (
        <div className={'curse-list'}>
            {
                curses.map((cursesSingle) => {
                    return (
                        <div onClick={() => {
                            if (cursesSingle.status == "Активен") {
                                if (cursesSingle.users.filter((userFilter) => {
                                    return userFilter.id == state_in['user_id'];
                                }).length > 0) {
                                } else {
                                    alert('У вас нет доступа')
                                }
                            }

                        }} className={"curse-single " + (cursesSingle.users.filter((userFilter) => {
                            return userFilter.id == state_in['user_id'];
                        }).length == 0 && 'curse-single-bly')}>
                            <div onClick={() => {

                                if (cursesSingle.users.filter((userFilter) => {
                                    return userFilter.id == state_in['user_id'];
                                }).length > 0) {
                                    navigate('/training/start/' + cursesSingle.id);
                                } else {
                                    alert('У вас нет доступа')
                                }

                            }} className="curse-single-head">
                                <p className={'text text-s18'}>{cursesSingle.name}</p>
                                <p className={'text text-s16'}>{cursesSingle.format}</p>
                            </div>
                            <div onClick={() => {
                                if (cursesSingle.users.filter((userFilter) => {
                                    return userFilter.id == state_in['user_id'];
                                }).length > 0) {
                                    navigate('/training/start/' + cursesSingle.id);
                                } else {
                                    alert('У вас нет доступа')
                                }
                            }} className="curse-single-img">
                                <img src={"https://back.hrbasic.kz/storage/" + cursesSingle.preview}/>
                            </div>
                            <div className="curse-single-edit">
                                {
                                    cursesSingle.status == 'Создан' && (
                                        <img onClick={() => {
                                            navigate('/training/edit/' + cursesSingle.id);
                                        }} src={edit}/>
                                    )
                                }
                            </div>
                        </div>
                    )
                })
            }


        </div>
    );

}

export default Empty;