import './scss/index.scss';

// @ts-ignore
import down from "./assets/icon/down-one.svg";
// @ts-ignore
import editF from "./assets/icon/edit.svg";
import {useState} from "react";
// @ts-ignore
import documentColor from "./assets/icon/document-color.svg";

interface Props {
    name: string;
    edit?: boolean;
    iconDocument?: boolean;
    drop?: Function;
    editOn?: Function;
}

const Empty: React.FC<Props> = ({name, drop, editOn, edit = true, iconDocument = false}) => {

    const [dropMenu, setDropMenu] = useState(false);
    return (
        <div className={'drop-open ' + (dropMenu && 'active')}>
            <div className="drop-open-head text text-s24">
                <img onClick={() => {
                    setDropMenu(!dropMenu);
                }} className={'down-icon'} src={down}/>

                {
                    iconDocument && (
                        <div className="upload-file-icon"
                             style={{
                                 backgroundImage: "url(" + documentColor + ")",
                                 marginLeft: "1.5rem",
                                 marginRight: "-0.5rem"
                             }}></div>
                    )
                }


                <b className={'drop-open-name'}>{name}</b>

                {
                    edit && (
                        <img onClick={() => {
                            if (editOn) {
                                editOn();
                            }
                        }} src={editF}/>
                    )
                }
            </div>

            {
                dropMenu && (
                    <div className="drop-open-body">
                        {
                            (drop ? drop() : '')
                        }
                    </div>
                )
            }

        </div>
    );

}

export default Empty;