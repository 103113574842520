import {Outlet, useNavigate} from "react-router-dom";

import './scss/index.scss';

// @ts-ignore
import CitySelectModal from '../../components/citySelectModal';
import React, {SetStateAction, useEffect, useState, useMemo} from "react";


import {getAuth, getUserInfo, postRegister} from "../../services";

import State from "../../redux/actions/StateActions";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";


import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadSlim} from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.


import logoImage from "../../assets/images/logo.svg";


const MyParticles = React.memo(({options}) => {
    return (
        <Particles
            id="tsparticles"
            options={options}
            particlesLoaded={() => console.log("Particles loaded")}
        />
    );
});

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [citySelect, setCitySelect] = useState({
        name: ""
    });

    const [rememberActive, setRememberActive] = useState(false);

    const [passShow, setPassShow] = useState(false);

    const [error, setError] = useState(false);
    const [errorRegistration, setErrorRegistration] = useState(false);

    const [registration, setRegistration] = useState(false);


    const [sendCode, setSendCode] = useState(false);


    const [inputRegPass, setInputRegPass] = useState('');

    const [regCode, setRegCode] = useState('');

    const [inputRegPassConform, setInputRegPassConform] = useState('');

    const [message, setMessage] = useState('');


    const [emailLogin, setEmailLogin] = useState('');
    const [passLogin, setPassLogin] = useState('');


    const [emailReg, setEmailReg] = useState('');
    const [passReg, setPassReg] = useState('');
    const [nameReg, setNameReg] = useState('');
    const [nameLastReg, setNameLastReg] = useState('');

    const options = useMemo(() => ({
        background: {
            color: {
                value: "#fff",
            },
        },
        fpsLimit: 120,
        particles: {
            color: {
                value: "#5549ED",
            },
            links: {
                color: "#5549ED",
                distance: 200,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 0.1,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 140,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: {min: 2, max: 6},
            },
        },
        detectRetina: true,
    }), []);


    useEffect(() => {

    }, [])


    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {

    };


    const navigate = useNavigate();

    return (
        <div className={'first-page'}>

            {
                init && (
                    <MyParticles options={options}/>
                )
            }


            <div className="first-page-right">

                {
                    !registration && (
                        <div className="login-form">
                            <div className="login-form-head">
                                <b className={'login-form-head-logo text text-head text-color-def text-s32'}>HR Basic </b>
                                <p className={'login-form-head-in text text-s32'} style={{color: "#1D2023"}}>Вход в
                                    аккаунт</p>
                            </div>

                            {
                                error && (
                                    <p className={'error-message text text-s14'}>
                                        {message}
                                    </p>
                                )
                            }

                            <form onSubmit={async (e) => {
                                e.preventDefault();


                                let auth = (
                                    await getAuth(
                                        {
                                            email: emailLogin,
                                            password: passLogin
                                        }
                                    )
                                );


                                if (auth?.access_token) {


                                    const userInfo = (await getUserInfo(auth?.access_token));

                                    // if (auth?.success) {
                                    //
                                    // }

                                    State(
                                        {
                                            token: auth?.access_token,
                                            user_id: auth?.user_id,
                                            expires_in: auth?.expires_in,
                                            // user: userInfo?.message,
                                        }
                                    );
                                    navigate('/dashboard');

                                }

                                if (auth?.error) {
                                    setError(true);
                                    setMessage(auth?.error);
                                }


                            }}>


                                <div class="input-placeholder">
                                    <p className={'text input-placeholder-name  text-gray text-s16'}>
                                        Логин
                                    </p>
                                    <div className="input-box">
                                        <input type="email" onChange={(e) => {
                                            setEmailLogin(e.target.value);
                                            setError(false);
                                        }} value={emailLogin}
                                               className={'input input-def text text-s14 ' + (error && 'input-error')}
                                               placeholder="Имя пользователя"
                                               style={{width: "100%"}}
                                               required/>
                                    </div>
                                </div>

                                <div class="input-placeholder">
                                    <div class="input-placeholder-head">
                                        <p className={'text input-placeholder-name text-gray text-s16'}>
                                            Пароль
                                        </p>
                                        <a href="#" className="forgot-password text text-s14">Забыли пароль?</a>
                                    </div>
                                    <div className="input-box">
                                        <input value={passLogin} onChange={(e) => {
                                            setPassLogin(e.target.value);
                                            setError(false);
                                        }} type={passShow ? "text" : "password"}
                                               className={'input input-def text text-s14 ' + (error && 'input-error')}
                                               placeholder="Введите пароль"
                                               style={{width: "100%"}}
                                               required/>
                                        <div onClick={() => {
                                            setPassShow(!passShow)
                                        }} className={"input-box-pass-off " + (passShow && 'active')}></div>
                                    </div>
                                </div>
                                <button className={'btn btn-def text text-s16 ' + (error && 'btn-error')}
                                        type="submit">Войти
                                </button>
                            </form>
                        </div>
                    )
                }

                {
                    registration && (
                        <div className="login-form">

                            {
                                !sendCode && (
                                    <div className="login-form-head">
                                        <img className={'logo logo-def'} src={logoImage} />
                                        <b className={'text text-s32'}>Регистрация нового аккаунта</b>
                                        <p className={'text text-s16'}>Введите свои данные для успешной регистрации</p>
                                    </div>
                                )
                            }

                            {
                                sendCode && (
                                    <div className="login-form-head">
                                        <img className={'logo logo-def'} src={logoImage}/>
                                        <b className={'text text-s32'}>Подтвердите свой адрес электронной почты</b>
                                        <p className={'text text-s16'}>Мы отправили код на почту <b>{emailReg}</b></p>
                                    </div>
                                )
                            }

                            {
                                errorRegistration && (
                                    <p className={'error-message text text-s14'}>
                                        {message}
                                    </p>
                                )
                            }


                            {
                                sendCode && (
                                    <form onSubmit={async (e) => {
                                        e.preventDefault();

                                        let auth = (
                                            await postRegister(
                                                {
                                                    name: nameReg,
                                                    name_last: nameLastReg,
                                                    email: emailReg,
                                                    code: regCode,
                                                    password: passReg
                                                }
                                            )
                                        );


                                        if (auth.success) {


                                            const userInfo = (await getUserInfo(auth.data.token));

                                            // if (userInfo?.success) {
                                            //
                                            //     setSendCode(true);
                                            //     setErrorRegistration(false);
                                            //     setMessage('');
                                            //
                                            //     State(
                                            //         {
                                            //             token: auth.data.token,
                                            //             user: userInfo?.message,
                                            //         }
                                            //     );
                                            // }

                                            State(
                                                {
                                                    token: auth.data.token,
                                                }
                                            );

                                            navigate('/project');
                                        }
                                        if (auth?.error) {
                                            setErrorRegistration(true);
                                            setMessage(auth?.error);
                                        }


                                    }}>


                                        <div className="input-box" style={{display: "flex", justifyContent: "center"}}>
                                            <input onChange={(e) => {
                                                setRegCode(e.target.value);
                                            }} value={regCode} type={"text"}
                                                   className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                   placeholder="Подтвердите код"
                                                   style={{width: "20rem"}}
                                                   required/>

                                        </div>

                                        <div className="login-form-footer text text-s14"
                                             style={{marginTop: "2.4rem", marginBottom: "3.2rem"}}>
                                        </div>
                                        <button className={'btn btn-def text text-s16 ' + (error && 'btn-error')}
                                                type="submit">Verify Account
                                        </button>
                                    </form>
                                )
                            }
                            {
                                !sendCode && (
                                    <form onSubmit={async (e) => {
                                        e.preventDefault();

                                        let auth = (
                                            await postRegister(
                                                {
                                                    name: nameReg,
                                                    name_last: nameLastReg,
                                                    email: emailReg,
                                                    password: passReg
                                                }
                                            )
                                        );


                                        if (auth.success) {
                                            setSendCode(true);
                                            setErrorRegistration(false);
                                            setMessage('');
                                        }
                                        if (auth?.error) {
                                            setErrorRegistration(true);
                                            setMessage(auth?.error);
                                        }


                                    }}>

                                        {
                                            (
                                                <div className="input-box-flex">
                                                    <input type="text" onChange={(e) => {
                                                        setErrorRegistration(false);
                                                        setNameReg(e.target.value);
                                                    }}
                                                           value={nameReg}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Введите Имя"
                                                           required/>

                                                    <input type="text" onChange={(e) => {
                                                        setErrorRegistration(false);
                                                        setNameLastReg(e.target.value);
                                                    }}
                                                           value={nameLastReg}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Фамилия"
                                                           required/>
                                                </div>
                                            )
                                        }


                                        <input type="email" onChange={(e) => {
                                            setErrorRegistration(false);
                                            setEmailReg(e.target.value);
                                        }}
                                               value={emailReg}
                                               className={'input input-def text text-s16 ' + (error && 'input-error')}
                                               placeholder="Введите email"
                                               required/>


                                        {
                                            (
                                                <div className="input-box">
                                                    <input onChange={(e) => {
                                                        setInputRegPass(e.target.value);
                                                        setPassReg(e.target.value);
                                                        setErrorRegistration(false);
                                                    }} value={inputRegPass} type={passShow ? "text" : "password"}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Придумайте пароль"
                                                           required/>
                                                    <div onClick={() => {
                                                        setPassShow(!passShow)
                                                    }} className={"input-box-pass-off " + (passShow && 'active')}></div>


                                                </div>
                                            )
                                        }
                                        {
                                            inputRegPass.length > 0 && inputRegPass.length < 8 && (
                                                <div className="error-pass">
                                                    <div className="error-pass-line"
                                                         style={{width: ((inputRegPass.length / 8) * 100) + "%"}}></div>
                                                    <p className={'text text-s12'}>
                                                        😖 Слабый. Пароль должен содержать не менее 8 символов, у
                                                        вас {inputRegPass.length}
                                                    </p>
                                                </div>
                                            )
                                        }


                                        {
                                            inputRegPass != '' && (
                                                <div className="input-box">
                                                    <input onChange={(e) => {
                                                        setErrorRegistration(false);
                                                        setInputRegPassConform(e.target.value);
                                                    }} value={inputRegPassConform} type={passShow ? "text" : "password"}
                                                           className={'input input-def text text-s16 ' + (error && 'input-error')}
                                                           placeholder="Подтвердите пароль"
                                                           required/>
                                                    <div onClick={() => {
                                                        setPassShow(!passShow)
                                                    }} className={"input-box-pass-off " + (passShow && 'active')}></div>
                                                </div>
                                            )
                                        }

                                        <div className="login-form-footer text text-s14"
                                             style={{marginTop: "2.4rem", marginBottom: "3.2rem"}}>
                                            <p>
                                        <span
                                            style={{color: "#718096"}}>  Создавая учетную запись, вы соглашаетесь с нашей </span>
                                                <b> Политикой
                                                    конфиденциальности и Политикой электронных коммуникаций.</b>
                                            </p>
                                        </div>
                                        <button className={'btn btn-def text text-s16 ' + (error && 'btn-error')}
                                                type="submit">Зарегистрироваться в Qisap
                                        </button>
                                    </form>
                                )
                            }


                            <div className="login-form-link">
                                <p className={'text text-s16'}>Уже есть аккаунт? <button onClick={() => {
                                    setErrorRegistration('');
                                    setMessage('');
                                    setRegistration(false);
                                }}
                                                                                         className="register">
                                    <b>Войдите</b></button>
                                </p>
                            </div>

                        </div>
                    )
                }

            </div>
        </div>
    );


}