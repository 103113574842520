import './scss/index.scss';
// @ts-ignore
import edit from "./assets/icon/edit.svg";

interface Props {
    name: string;
}

import {coursesList, courseArchive} from "../../services";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TableCustom from "../TableCustom";
import {NULL} from "sass";

const Empty: React.FC<Props> = ({name}) => {

    const state_in = useSelector(StateSlices);
    const [curses, setCurses] = useState([]);
    const [reset, setReset] = useState(false);
    const navigate = useNavigate();

    async function getAllCurse() {

        setCurses((await coursesList(state_in['token'])).data);

    }

    useEffect(() => {

        getAllCurse();

    }, [reset]);

    return (
        <div className={'curse-list'} style={{width: "100%", marginLeft: "0"}}>


            <TableCustom
                whType={'rem'}
                th={{
                    name: {
                        name: 'Название',
                        w: 12,
                        option: null
                    },
                    format: {
                        name: 'Формат',
                        w: 12,
                        option: null
                    },
                    status: {
                        name: 'Статус',
                        w: 12,
                        option: ((row) => {
                            return (
                                <div className="task-option">
                                    <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {row['status']} </a>
                                </div>
                            )
                        })
                    },
                    start: {
                        name: 'Дата  начала',
                        w: 12,
                        option: null
                    },
                    deadline: {
                        name: 'Дата окончания',
                        w: 12,
                        option: null
                    },
                    comment: {
                        name: 'Комментарии',
                        w: 12,
                        option: ((row) => {
                            return (
                                <div className="task-option">
                                </div>
                            )
                        })
                    },

                    options: {
                        name: 'Действие',
                        w: 12,
                        option: ((row) => {
                            return (
                                <div>
                                    <button onClick={async () => {

                                        const returnInfo = await courseArchive({
                                            course_id: row.id
                                        }, state_in['token']);
                                        if (!returnInfo?.success) {
                                            alert("Курс нельзя удалить, у курса есть активные юзеры")
                                        } else {
                                            setReset(!reset);
                                        }

                                    }} className={'text text-s14'} style={{color: "red"}}>удалить
                                    </button>
                                </div>
                            )
                        })
                    },
                }}
                tr={
                    curses
                }
            />
        </div>
    );

}

export default Empty;