import './scss/index.scss';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import StateSlices from '../../redux/slices/State';
import Layout from '../../components/Layout';

import Drop from '../personal/components/Drop';
import InputMini from '../../components/InputMini';
import backImg from './assets/icon/back.svg';
import deleteImg from './assets/icon/delete.svg';
import plusImg from './assets/icon/plus.svg';
import SelectMini from '../../components/SelectMini';

// Импорт сервиса (из файла services/index.js)
import {coursesList, createCourse, userList} from '../../services';
import {useNavigate} from "react-router-dom";

export default function AboutRoute() {
    const state_in = useSelector(StateSlices);
    const navigate = useNavigate();

    // Состояния для основных полей:
    const [courseFormat, setCourseFormat] = useState('');
    const [courseDuration, setCourseDuration] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [deadlineDate, setDeadlineDate] = useState('');
    const [deadlineTime, setDeadlineTime] = useState('');
    const [courseSpeaker, setCourseSpeaker] = useState('');
    const [courseLisner, setCourseLisner] = useState('');
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');

    const [loading, setLoading] = useState(false);

    const [coursePreview, setCoursePreview] = useState(null);
    const [coursePriority, setCoursePriority] = useState('не срочно'); // "срочно" / "не срочно"

    // Массив ссылок (пример, если нужно)
    const [links, setLinks] = useState([]);

    // Список курсов (пример вашего кода)
    const [courses, setCourses] = useState([]);

    // Список пользователей (для выпадающего списка спикеров)
    const [userListBox, setUserListBox] = useState([]);

    // -----------------------------
    // Список вопросов (тестов)
    // -----------------------------
    const [questions, setQuestions] = useState([]);

    // Функция: добавить новый вопрос
    const handleAddQuestion = () => {
        setQuestions((prev) => [
            ...prev,
            {
                id: Date.now(),
                question: '',
                answers: [
                    {text: '', is_correct: false},
                ],
            },
        ]);
    };

    // Функция: удалить вопрос
    const handleDeleteQuestion = (questionId) => {
        setQuestions((prev) => prev.filter((q) => q.id !== questionId));
    };

    // Функция: изменить текст вопроса
    const handleQuestionChange = (questionId, value) => {
        setQuestions((prev) =>
            prev.map((q) =>
                q.id === questionId
                    ? {...q, question: value}
                    : q
            )
        );
    };

    // Функция: добавить вариант ответа
    const handleAddAnswer = (questionId) => {
        setQuestions((prev) =>
            prev.map((q) =>
                q.id === questionId
                    ? {
                        ...q,
                        answers: [
                            ...q.answers,
                            {text: '', is_correct: false},
                        ],
                    }
                    : q
            )
        );
    };

    // Функция: удалить вариант ответа
    const handleDeleteAnswer = (questionId, index) => {
        setQuestions((prev) =>
            prev.map((q) => {
                if (q.id === questionId) {
                    const updatedAnswers = [...q.answers];
                    updatedAnswers.splice(index, 1);
                    return {...q, answers: updatedAnswers};
                }
                return q;
            })
        );
    };

    // Функция: изменить поле (text или is_correct) у варианта ответа
    const handleAnswerChange = (questionId, index, field, value) => {
        setQuestions((prev) =>
            prev.map((q) => {
                if (q.id === questionId) {
                    const updatedAnswers = [...q.answers];
                    updatedAnswers[index] = {
                        ...updatedAnswers[index],
                        [field]: value,
                    };
                    return {...q, answers: updatedAnswers};
                }
                return q;
            })
        );
    };
    // -----------------------------
    // -----------------------------

    // Загрузка списка курсов и пользователей
    useEffect(() => {
        async function fetchCourses() {
            try {
                const data = await coursesList(state_in['token']);
                const userListS = await userList(state_in['token']);
                setUserListBox(
                    userListS.data.map((userMap) => ({
                        ...userMap,
                        value: userMap.id
                    }))
                );

                setCourses(data);
            } catch (error) {
                console.error('Ошибка при загрузке курсов:', error);
            }
        }

        fetchCourses();
    }, [state_in]);

    // Функция сохранения курса
    const handleSaveCourse = async () => {
        if (!loading) {
            setLoading(true);

            try {
                // Формируем дату начала и конца: "2024-11-01T10:00:00"
                const fullStart = startDate
                    ? `${startDate}T${startTime || '00:00'}`
                    : '';
                const fullDeadline = deadlineDate
                    ? `${deadlineDate}T${deadlineTime || '00:00'}`
                    : '';

                // Собираем итоговый объект данных
                const data = {
                    name: courseName,
                    format: courseFormat,
                    speaker: courseSpeaker || '', // ID спикера
                    start: fullStart,
                    listeners: courseLisner,
                    deadline: fullDeadline,
                    duration: Number(courseDuration) || 0,
                    preview: coursePreview, // файл-обложка
                    priority: coursePriority,
                    description: courseDescription,

                    // Если у вас есть файлы кроме обложки — добавляете их сюда
                    files: [],

                    // Ссылки, если нужно
                    links,

                    // И главное — массив вопросов
                    questions,
                };

                const response = await createCourse(data, state_in['token']);

                if (response?.error) {
                    let text = "";
                    Object.entries(response?.error).forEach((error) => {
                        text += error[0] + ": " + error[1][0] + " \n";
                    });
                    alert(`Ошибка: ${text}`);
                } else {
                    alert('Курс успешно создан!');
                    navigate('/training');
                }
            } catch (error) {
                console.error('Ошибка при сохранении курса:', error);
            }
            setLoading(false);

        }
    };

    return (
        <Layout
            headTitle={
                <div className="text-head-box">
                    <span style={{width: '18.5rem'}}></span>
                    <p className="text text-gray text-s20">Главное меню</p>
                    <span style={{width: '1rem'}}></span>
                    <p className="text text-gray text-s20">/</p>
                    <span style={{width: '1rem'}}></span>
                    <p className="text text-gray text-s20">Обучение</p>
                    <span style={{width: '1rem'}}></span>
                    <p className="text text-gray text-s20">/</p>
                    <span style={{width: '1rem'}}></span>
                    <p className="text text-un text-bly text-s20">Создать курсы</p>
                </div>
            }
        >
            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className="text text-s36">Создать курсы</b>
                    </div>
                </div>
            </div>

            <div className="training-white">
                <img onClick={() => {
                    navigate('/training');
                }} className="img-back-btn" src={backImg} alt="back-btn"/>

                <div className="workplace-space workplace-space-bly">
                    <Drop
                        name="Общие настройки"
                        edit={false}
                        drop={() => {
                            return (
                                <div className="task-personal-table">
                                    <div className="filter-box__inputs">
                                        <div className="drop-input-flex">
                                            <SelectMini
                                                options={[
                                                    {name: 'курс', value: 'курс'},
                                                    {name: 'мастер класс', value: 'мастер класс'},
                                                    {name: 'воркшоп', value: 'воркшоп'},
                                                    {name: 'документ', value: 'документ'},
                                                ]}
                                                placeholder="Выбрать тип"
                                                value={courseFormat}
                                                name="Формат"
                                                onChange={(val) => setCourseFormat(val)}
                                            />
                                            <InputMini
                                                name="Длительность "
                                                value={courseDuration}
                                                placeholder="0"
                                                onChange={(e) => setCourseDuration(e.target.value)}
                                            />
                                        </div>
                                        <div className="drop-input-flex">
                                            <div className="input-time">
                                                <InputMini
                                                    type="date"
                                                    name="Начало "
                                                    placeholder="Выберите дату "
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                                <SelectMini
                                                    type="mini"
                                                    options={[
                                                        {name: '00:00', value: '00:00'},
                                                        {name: '01:00', value: '01:00'},
                                                        {name: '02:00', value: '02:00'},
                                                        {name: '03:00', value: '03:00'},
                                                        {name: '04:00', value: '04:00'},
                                                        {name: '05:00', value: '05:00'},
                                                        {name: '06:00', value: '06:00'},
                                                        {name: '07:00', value: '07:00'},
                                                        {name: '08:00', value: '08:00'},
                                                        {name: '09:00', value: '09:00'},
                                                        {name: '10:00', value: '10:00'},
                                                        {name: '11:00', value: '11:00'},
                                                        {name: '12:00', value: '12:00'},
                                                        {name: '13:00', value: '13:00'},
                                                        {name: '14:00', value: '14:00'},
                                                        {name: '15:00', value: '15:00'},
                                                        {name: '16:00', value: '16:00'},
                                                        {name: '17:00', value: '17:00'},
                                                        {name: '18:00', value: '18:00'},
                                                        {name: '19:00', value: '19:00'},
                                                        {name: '20:00', value: '20:00'},
                                                        {name: '21:00', value: '21:00'},
                                                        {name: '22:00', value: '22:00'},
                                                        {name: '23:00', value: '23:00'},
                                                    ]}
                                                    placeholder=""
                                                    value={startTime}
                                                    name="startTime"
                                                    onChange={(val) => setStartTime(val)}
                                                />
                                            </div>
                                            <div className="input-time">
                                                <InputMini
                                                    type="date"
                                                    name="Дедлайн "
                                                    placeholder="Выберите дату "
                                                    value={deadlineDate}
                                                    onChange={(e) => setDeadlineDate(e.target.value)}
                                                />
                                                <SelectMini
                                                    type="mini"
                                                    options={[
                                                        {name: '00:00', value: '00:00'},
                                                        {name: '01:00', value: '01:00'},
                                                        {name: '02:00', value: '02:00'},
                                                        {name: '03:00', value: '03:00'},
                                                        {name: '04:00', value: '04:00'},
                                                        {name: '05:00', value: '05:00'},
                                                        {name: '06:00', value: '06:00'},
                                                        {name: '07:00', value: '07:00'},
                                                        {name: '08:00', value: '08:00'},
                                                        {name: '09:00', value: '09:00'},
                                                        {name: '10:00', value: '10:00'},
                                                        {name: '11:00', value: '11:00'},
                                                        {name: '12:00', value: '12:00'},
                                                        {name: '13:00', value: '13:00'},
                                                        {name: '14:00', value: '14:00'},
                                                        {name: '15:00', value: '15:00'},
                                                        {name: '16:00', value: '16:00'},
                                                        {name: '17:00', value: '17:00'},
                                                        {name: '18:00', value: '18:00'},
                                                        {name: '19:00', value: '19:00'},
                                                        {name: '20:00', value: '20:00'},
                                                        {name: '21:00', value: '21:00'},
                                                        {name: '22:00', value: '22:00'},
                                                        {name: '23:00', value: '23:00'},
                                                    ]}
                                                    placeholder=""
                                                    value={deadlineTime}
                                                    name="deadlineTime"
                                                    onChange={(val) => setDeadlineTime(val)}
                                                />
                                            </div>
                                        </div>
                                        <div className="drop-input-flex">
                                            <SelectMini
                                                options={userListBox}
                                                placeholder="Выбрать"
                                                value={courseSpeaker}
                                                name="Спикер курса"
                                                onChange={(val) => setCourseSpeaker(val)}
                                            />
                                            {/* Пример селекта для "слушателей", пока не используем */}
                                            <SelectMini
                                                options={userListBox}
                                                placeholder="Выбрать"
                                                onChange={(val) => setCourseLisner([val])}
                                                value={courseLisner.length > 0 ? courseLisner[0] : ''}
                                                name="Слушатели курса"
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>

                <div className="workplace-space workplace-space-input-margin">
                    <div className="task-personal-table">
                        <div className="filter-box__inputs">
                            <div className="drop-input-flex" style={{width: '100%'}}>
                                {/* Обложка */}
                                <InputMini
                                    full
                                    type="file"
                                    name="Обложка для курса"
                                    placeholder=""
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            setCoursePreview(e.target.files[0]);
                                        }
                                    }}
                                />
                                {/* Название курса */}
                                <InputMini
                                    full
                                    name="Название курса"
                                    placeholder="Напишите название"
                                    value={courseName}
                                    onChange={(e) => setCourseName(e.target.value)}
                                />
                                {/* Описание курса */}
                                <InputMini
                                    phLeft
                                    type="textLarge"
                                    name="Описание курса"
                                    placeholder="Напишите текст"
                                    value={courseDescription}
                                    onChange={(e) => setCourseDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Список тестов (questions) */}
                {questions.map((q) => (
                    <div key={q.id} className="workplace-space workplace-space-b-top">
                        <div className="task-personal-table">
                            <div className="filter-box__inputs">
                                <div className="drop-input-flex" style={{width: '100%'}}>
                                    <div className={'input-sw-box-ws'}>
                                        <InputMini
                                            name="Введите вопрос"
                                            placeholder=""
                                            full
                                            value={q.question}
                                            onChange={(e) =>
                                                handleQuestionChange(q.id, e.target.value)
                                            }
                                        />
                                        <button
                                            onClick={() => handleDeleteQuestion(q.id)}
                                            className={'add-aw-item text text-s14'}
                                            style={{color: "red"}}
                                        >
                                            <img src={deleteImg}/>
                                        </button>
                                    </div>
                                    <div className="question-list-box">
                                        {q.answers.map((answer, idx) => (
                                            <div className="question-in" key={idx}>
                                                <input
                                                    type="checkbox"
                                                    checked={answer.is_correct}
                                                    onChange={(e) =>
                                                        handleAnswerChange(
                                                            q.id,
                                                            idx,
                                                            'is_correct',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                <input
                                                    placeholder="Вариант ответа"
                                                    value={answer.text}
                                                    className={'question-input text text-s14'}
                                                    onChange={(e) =>
                                                        handleAnswerChange(
                                                            q.id,
                                                            idx,
                                                            'text',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <button
                                                    className={'close-icon-aw'}
                                                    onClick={() => handleDeleteAnswer(q.id, idx)}
                                                >
                                                    <img src={deleteImg}/>
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="question-btn">
                                        <button
                                            className={'add-aw-item text text-s14'}
                                            onClick={() => handleAddAnswer(q.id)}
                                        >
                                            <img src={plusImg}/> Добавить вариант ответа
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>

            {/* Кнопки управления */}
            <div className="center-flex">
                <div
                    onClick={handleAddQuestion}
                    className="btn-add-files text text-s14"
                    style={{backgroundColor: '#6418C3', marginTop: '4rem'}}
                >
                    Добавить тест
                </div>

                <div
                    className="btn-add-files text text-s14"
                    style={{backgroundColor: '#6418C3', marginTop: '1rem'}}
                    onClick={handleSaveCourse}
                >
                    Сохранить
                </div>
                <div
                    className="btn-add-files text text-s14"
                    style={{backgroundColor: '#6418C3', marginTop: '1rem'}}
                >
                    Создать сертификат
                </div>
            </div>
        </Layout>
    );
}
